import { productionawsEnvironment } from '@iad-digital/iad-ngx-core';

export const environment = {
  ...productionawsEnvironment,
  apis: [
    ...productionawsEnvironment.apis,
    {
      id: 'bm',
      host: 'becomemanager.api.playiad.com',
      url: 'https://becomemanager.api.playiad.com',
      options: {}
    }
  ],
  urls: [
    ...productionawsEnvironment.urls,
    {
      id: 'ng',
      url: 'https://becomemanager.playiad.com'
    }
  ],
  locale: {
    ...productionawsEnvironment.locale,
    directories: [...productionawsEnvironment.locale.directories, 'become-manager']
  },
  enabledJwt: true
};
