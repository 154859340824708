import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import {
  AuthConstant,
  ComponentsModule,
  CORE_CONFIG,
  CoreModule,
  CoreStateModule,
  GtmHelper,
  DataDogHelper,
  translateLoader,
} from '@iad-digital/iad-ngx-core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateFakeCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export function tokenGetter(): string {
  return JSON.parse(sessionStorage.getItem(AuthConstant.JWT_TOKEN_KEY));
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    AppRoutingModule,
    CoreModule,
    CoreStateModule,
    ComponentsModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateFakeCompiler,
        useClass: TranslateMessageFormatCompiler
      },
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoader,
        deps: [CORE_CONFIG, HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: [environment.apis[environment.apis.length - 1].host]
      }
    }),
    BrowserAnimationsModule,
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    Angulartics2Module.forRoot({ developerMode: !environment.production })
  ],
  providers: [
    { provide: CORE_CONFIG, useValue: environment },
    { provide: LOCALE_ID, useValue: environment.locale.default },
    GtmHelper,
    DataDogHelper
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(translate: TranslateService) {
    // pipe has only access to en-US locale data by default.
    // here we want to localize the dates in another language exemple fr-FR, we have to import data for other locales.
    environment.locale.availables.forEach((l: any) => registerLocaleData(l.locale, l.name));
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(environment.locale.default);
    translate.use(environment.locale.default);
    sessionStorage.setItem('env', environment.name);
    sessionStorage.setItem('site', 'iad_digital_become_manager');
  }
}
