import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { ConcessionSlug, fromUserSelectors, User } from '@iad-digital/iad-ngx-core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, skipUntil, take } from 'rxjs/operators';

import * as fromBm from '../store/bm-home/reducers';

@Injectable({
  providedIn: 'root'
})
export class EsItGuard implements CanActivate {
  constructor(private store: Store<fromBm.State>) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const userFetch$ = this.store.pipe(
      select(fromUserSelectors.selectUser),
      filter((user: User) => !!user)
    );
    // Blocking guard, until user is not null
    return this.store.pipe(
      select(fromUserSelectors.selectUser),
      skipUntil(userFetch$),
      map((user: User) =>
        [
          ConcessionSlug.FRANCE,
          ConcessionSlug.PORTUGAL,
          ConcessionSlug.DEUTSCHLAND,
          ConcessionSlug.UNITED_KINGDOM,
          ConcessionSlug.FLORIDA
        ].includes(user.concession.slug)
      ),
      take(1)
    );
  }
}
