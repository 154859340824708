import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  BreadCrumb,
  BreadCrumbCommonL10n,
  ContentComponent,
  Error403Component,
  Error404Component,
  Profile,
  RedirectComponent,
  RedirectGuard,
  redirectToCdi,
} from '@iad-digital/iad-ngx-core';

import { BreadCrumbBmL10n } from './become-manager/shared/breadcrumb';
import { EsItGuard } from './become-manager/shared/guards/ES-IT.guard';
import { ProfileGuard } from './become-manager/shared/guards/profile.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: RedirectComponent,
        canActivate: [RedirectGuard],
        data: { ...redirectToCdi('home', BreadCrumbCommonL10n.home) }
      },
      {
        path: 'agent',
        canActivate: [ProfileGuard],
        data: {
          breadcrumb: new BreadCrumb('agent', BreadCrumbCommonL10n.home),
          profile: Profile.AGENT
        },
        component: ContentComponent,
        children: [
          {
            path: '',
            redirectTo: '/agent/home',
            pathMatch: 'full'
          },
          {
            path: 'home',
            component: RedirectComponent,
            canActivate: [RedirectGuard],
            data: { ...redirectToCdi('home', BreadCrumbCommonL10n.home) }
          },
          {
            path: 'network',
            data: { breadcrumb: new BreadCrumb('career', BreadCrumbBmL10n.career) },
            children: [
              {
                path: '',
                component: RedirectComponent,
                canActivate: [RedirectGuard],
                data: { ...redirectToCdi('home', BreadCrumbCommonL10n.home) }
              },
              {
                canActivate: [EsItGuard],
                path: 'become-manager',
                data: { breadcrumb: new BreadCrumb('bm', BreadCrumbBmL10n.home) },
                loadChildren: () => import('./become-manager/bm.module').then(m => m.BecomeManagerModule)
              }
            ]
          }
        ]
      },
      {
        path: 'headquarter',
        canActivate: [ProfileGuard],
        data: {
          breadcrumb: new BreadCrumb('headquarter', BreadCrumbCommonL10n.home),
          profile: Profile.HEADQUARTER
        },
        component: ContentComponent,
        children: [
          {
            path: '',
            redirectTo: '/headquarter/home',
            pathMatch: 'full'
          },
          {
            path: 'home',
            component: RedirectComponent,
            canActivate: [RedirectGuard],
            data: { ...redirectToCdi('home', BreadCrumbCommonL10n.home) }
          },
          {
            path: 'network',
            data: {
              breadcrumb: new BreadCrumb('network', BreadCrumbBmL10n.network)
            },
            children: [
              {
                path: '',
                component: RedirectComponent,
                canActivate: [RedirectGuard],
                data: {
                  redirect: {
                    rootKeyUrl: 'wis',
                    url: 'wis/headquarter/network/overview'
                  }
                }
              },
              {
                path: 'become-manager',
                data: { breadcrumb: new BreadCrumb('directory', BreadCrumbBmL10n.directory) },
                children: [
                  {
                    path: '',
                    component: RedirectComponent,
                    canActivate: [RedirectGuard],
                    data: {
                      redirect: {
                        rootKeyUrl: 'wis',
                        url: 'wis/headquarter/network/directory'
                      }
                    }
                  },
                  {
                    path: '',
                    canActivate: [EsItGuard],
                    loadChildren: () => import('./become-manager/bm.module').then(m => m.BecomeManagerModule)
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '403',
        component: Error403Component
      }
    ]
  },
  {
    path: '**',
    component: Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
